import React from 'react'
// import { getDisplayName } from 'next-server/dist/lib/utils'
import { defaultLocale, isLocale, locales } from './config'
import { LocaleProvider } from './context'

const component = (WrappedPage) => {
  const WithLocale = ({ locale, ...pageProps }) => {
    if (!locale) {
      // if no valid locale, fallback to default
      return (
        <LocaleProvider lang={defaultLocale}>
          <WrappedPage {...pageProps} />
        </LocaleProvider>
      )
    }
    return (
      <LocaleProvider lang={locale}>
        <WrappedPage {...pageProps} />
      </LocaleProvider>
    )
  }

  return WithLocale
}

export default component